import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'

function AppChrome( { 
  navigationBar = null, 
  footerPageSegments = null,
  children, 
} ) {
  return (
    <>
      <Header navigationBar={ navigationBar } />
      <StyledAppMain>
        { children }
      </StyledAppMain>
      <Footer pageSegments={ footerPageSegments } />
    </>
  )
}

const StyledAppMain = styled.div`
  margin: calc( 42px + ( 4 * var( --stack-basis ) ) ) auto 0 auto;
  /* max-width: 2000px; */
`

export default AppChrome
