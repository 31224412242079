import AppChrome from '../../../components/AppChrome'
import HeroPhoto from '../../../components/HeroPhoto'
import PageSegments from '../../../components/pageSegments/PageSegments'

export { getServerSideProps } from '../../../graphql/partnerHomepage'

function PartnerHome( { entry: homepage } ) {
  return (
    <div>
      <AppChrome>
        <HeroPhoto 
          title={ homepage.mainTitle }
          subtitle={ homepage.subtitle }
          image={ homepage.mainImage[ 0 ] }
          buttonLabel={ homepage.buttonLabel }
          buttonLink={ homepage.buttonLink }
          partnerLabel={ homepage.partnerLabel }
          partnerLogos={ homepage.logos }
          testimonial={ homepage.testimonial[ 0 ] || null }
        />
        <PageSegments pageSegments={ homepage.pageSegments } />
      </AppChrome>
    </div>
  )
}

export default PartnerHome
