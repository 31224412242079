import styled from 'styled-components'
import Container, { Expander } from './layout/Container'
import Background from './layout/Background'
import FlexWrap, { FlexWrapItem } from './layout/FlexWrap'
import Stack, { StackItem } from './layout/Stack'
import Image from './Image'
import Button from './Button'
import FiveStars from './icons/FiveStars'
import PartnerLogo from './PartnerLogo'

function HeroPhoto( {
  title,
  subtitle,
  image,
  buttonLabel,
  buttonLink,
  partnerLabel,
  partnerLogos,
  testimonial,
} ) {
  return (
    <Expander>
      <Background $color="ivory">
        <Container $containerType="full">
          <StyledHeroGrid>
            <StyledHeroContent>
              <Stack
                $flex
                $top={ 5 }
                $bottom={ 5 }
                $between={ 2 }
              >
                <h1>{ title }</h1>
                <p>{ subtitle }</p>
                { buttonLink && buttonLabel && (
                  <StackItem
                    $top={ 2 }
                  >
                    <Button 
                      color="red"
                      href={ buttonLink }
                    >
                      { buttonLabel }
                    </Button>
                  </StackItem>
                ) }
                <StackItem
                  $top="auto"
                >
                  <FlexWrap $gap={ 2 }>
                    { partnerLogos?.length > 0 && (
                      <PartnerLogo
                        label={ partnerLabel }
                        logos={ partnerLogos }
                      />
                    ) }
                    { testimonial && (
                      <FlexWrapItem 
                        $flexBasis="350px"
                        $flexGrow={ 1 }
                      >
                        <StyledTestimonial>
                          <FiveStars />
                          <p>
                            “{ testimonial.description }”
                            <span>~ { testimonial.title }</span>
                          </p>
                        </StyledTestimonial>
                      </FlexWrapItem>
                    ) }
                  </FlexWrap>
                </StackItem>
              </Stack>
            </StyledHeroContent>
            <StyledPhoto>
              <Image
                src={ image.url }
                alt={ image.alt }
                dateModified={ image.dateModified }
                layout="fill"
                objectFit="cover"
              />
            </StyledPhoto>
          </StyledHeroGrid>
        </Container>
      </Background>
    </Expander>
  )
}

const breakpoint = 600

const StyledHeroGrid = styled.div`
  display: flex;

  @media ( max-width: ${ breakpoint }px ) {
    flex-direction: column;
  }
`

const StyledPhoto = styled.div`
  position: relative;
  overflow: hidden;
  flex: 0 0 calc( 50% - var( --grid-gutter ) );
  margin-left: auto;
  aspect-ratio: 1.3/1;
  border-bottom-left-radius: 100rem;
  border-top-left-radius: 100rem;

  @media ( min-width: ${ breakpoint + 1 }px ) and ( max-width: 950px ) {
    flex-basis: 40%;
    height: 65vw;
    border-top-left-radius: 0;
  }

  @media ( max-width: ${ breakpoint }px ) {
    height: 65vw;
    margin-left: 0;
    border-radius: 0;
  }
`

const StyledHeroContent = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 600px;
  padding-left: calc( 3 * var( --grid-gutter ) );
  padding-right: calc( 2 * var( --grid-gutter ) );

  @media ( max-width: ${ breakpoint }px ) {
    padding-left: var( --container-margin-width );
    padding-right: var( --container-margin-width );
  }

  @media ( min-width: 1650px ) {
    padding-left: 0;
  }

  h1 {
    ${ p => p.theme.typo.heroTitle };
    line-height: 1.1;
  }

  p {
    ${ p => p.theme.typo.heroSubTitle };
  }
`

const StyledTestimonial = styled.div`
  p {
    margin: 0;
    font-size: ${ p => p.theme.typo.sizes.dropped };

    span {
      margin-left: 0.5em;
      font-weight: 600;
      white-space: nowrap;
    }
  }
`

export default HeroPhoto
