import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { useCraft } from '../lib/craft'
import TelephoneIcon from './icons/TelephoneIcon'

export default function OpenClosed() {
  const { locale, defaultLocale } = useRouter()
  const craftData = useCraft()
  const [isOpen, setIsOpen] = useState( null )
  const [nextOpen, setNextOpen] = useState( null )
  const serializedOpeningTimes = JSON.stringify( craftData?.pageProps?.businessHours?.openingTimes )
  
  const openingTimes: Array<any> = useMemo( 
    () => craftData?.pageProps?.businessHours?.openingTimes || [], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serializedOpeningTimes],
  )

  const weekdayFormatter = new Intl.DateTimeFormat( 
    [locale || defaultLocale || 'en_US'], 
    {
      weekday: 'long',
    },
  )
  
  const timeFormatter = new Intl.DateTimeFormat( 
    [locale || defaultLocale || 'en_US'], 
    {
      timeStyle: 'short',
    },
  )
  
  useEffect( () => {
    let timer = null

    if ( timer ) {
      clearInterval( timer )
    }

    timer = setInterval( () => {
      // check if open
      setIsOpen( areWeOpen( openingTimes ) )
      setNextOpen( whenAreWeNextOpen( openingTimes ) )
    }, 1000 * 60 )
    
    setIsOpen( areWeOpen( openingTimes ) )
    setNextOpen( whenAreWeNextOpen( openingTimes ) )
  }, [openingTimes] )

  if ( isOpen === null ) {
    return null
  }

  if ( isOpen ) {
    return (
      <StyledOpenClosed>
        <span>
          <FormattedMessage
            description="[Brixel] is open"
            defaultMessage="is open"
            id="FthgtD"
          />
        </span>
        <CallUs />
      </StyledOpenClosed>
    )
  }

  if ( nextOpen && nextOpen.getDate() !== new Date().getDate() ) {
    return (
      <StyledOpenClosed>
        <FormattedMessage
          description="opens on [Monday/Tuesday/etc…]"
          defaultMessage="opens on "
          id="RlDzFA"
        />
        { ' ' }{ weekdayFormatter.format( nextOpen ) }
      </StyledOpenClosed>
    )
  }
  
  if ( nextOpen ) {
    return (
      <StyledOpenClosed>
        <FormattedMessage
          description="opens at [9am]"
          defaultMessage="opens at "
          id="HS4fmx"
        />
        { ' ' }{ timeFormatter.format( nextOpen ) }
      </StyledOpenClosed>
    )
  }

  return null
}

function CallUs() {
  return (
    <StyledCallUs href="tel:+41 44 552 57 30">
      <StyledPhoneIcon>
        <TelephoneIcon />
      </StyledPhoneIcon>
      +41 44 552 57 30
    </StyledCallUs>
  )
}

const StyledOpenClosed = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 4px;
  color: ${ p => p.theme.colors.green400 };
  font-size: ${ p => p.theme.typo.sizes.dropped };

  > span {
    @media ( max-width: 600px ) {
      display: none;
    }
  }
`

const StyledCallUs = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: inherit;
  color: inherit;
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  transition: text-decoration-color .3s ease-out;
  &:hover {
    text-decoration-color: inherit;
  }
`

const StyledPhoneIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 20rem;
  background-color: ${ p => p.theme.colors.green100 };
`

function areWeOpen( openingTimes: Array<{ day: string, time: string }>, now = new Date() ) {
  const today = now.getDay()
  const todaysOpeningTimes = openingTimes.filter( ot => ot.day === daysOfTheWeek[ today ] )
  const timeSlots = todaysOpeningTimesToSlots( now, todaysOpeningTimes )

  for ( let i = 0, len = timeSlots.length; i < len; i += 1 ) {
    const slot = timeSlots[ i ]
  
    if ( now >= slot.opens && now <= slot.closes ) {
      return true
    }
  }

  return false
}

function whenAreWeNextOpen( openingTimes: Array<{ day: string, time: string }>, now = new Date() ) {
  const nextOpenToday = whenAreWeNextOpenOnDay( now, openingTimes )

  if ( nextOpenToday ) {
    return nextOpenToday
  }

  for ( let i = 0, len = daysOfTheWeek.length; i < len; i += 1 ) {
    const thisDay = new Date( now )
    thisDay.setUTCHours( 0 )
    thisDay.setUTCMinutes( 0 )
    thisDay.setUTCSeconds( 0 )
    thisDay.setDate( thisDay.getDate() + i )
    const nextOpenThisDay = whenAreWeNextOpenOnDay( thisDay, openingTimes )

    if ( nextOpenThisDay ) {
      return nextOpenThisDay
    }
  }

  return false
}

function whenAreWeNextOpenOnDay( day: Date, openingTimes: Array<{ day: string, time: string }> ) {
  const today = day.getDay()
  const todaysOpeningTimes = openingTimes.filter( ot => ot.day === daysOfTheWeek[ today ] )
  const timeSlots = todaysOpeningTimesToSlots( day, todaysOpeningTimes )

  for ( let i = 0, len = timeSlots.length; i < len; i += 1 ) {
    const slot = timeSlots[ i ]
  
    if ( day < slot.opens ) {
      return slot.opens
    }
  }

  return false
}

function todaysOpeningTimesToSlots( now, openingTimes: Array<{ day: string, time: string }> ) {
  return openingTimes.reduce( ( slots, ot, currentIndex ) => {
    if ( currentIndex % 2 ) {
      return slots
    }

    const openingTime = new Date( ot.time )
    const closingTime = new Date( openingTimes[ currentIndex + 1 ]?.time || null )

    openingTime.setFullYear( now.getFullYear() )
    openingTime.setMonth( now.getMonth() )
    openingTime.setDate( now.getDate() )
    
    closingTime.setFullYear( now.getFullYear() )
    closingTime.setMonth( now.getMonth() )
    closingTime.setDate( now.getDate() )

    return [
      ...slots,
      {
        opens: openingTime,
        closes: closingTime,
      },
    ]
  }, [] )
}

const daysOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]
