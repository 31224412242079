import styled from 'styled-components'
import { useCraft } from '../lib/craft'
import Container from './layout/Container'
import Stack from './layout/Stack'
import BrixelLogo from './BrixelLogo'
import Link from './Link'
import NavigationBar from './navigationBar/NavigationBar'
import OpenClosed from './OpenClosed'

function Header( { 
  logoLink = '/',
  navigationBar = null, 
  openClosed = null,
  children = null,
} ) {
  const craftData = useCraft()
  const navBar = navigationBar || craftData?.pageProps?.header?.[ 0 ]?.navigationBar
  const showOpenClosed = openClosed === null
    ? craftData?.pageProps?.businessHours?.openSign
    : openClosed

  return (
    <StyledHeader>
      <Container $containerType="small-margin">
        <Stack
          $top={ 2 }
          $bottom={ 2 }
        >
          <StyledHeaderInner>
            <Link href={ logoLink }>
              <BrixelLogo breakpoint={ 500 } />
            </Link>
            { showOpenClosed && <OpenClosed /> }
            { !!navBar?.length && (
              <NavigationBar
                navigationBar={ navBar }
              />
            ) }
            {children}
          </StyledHeaderInner>
        </Stack>
      </Container>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${ p => p.theme.colors.realWhite };
`

const StyledHeaderInner = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  > * + *:last-child {
    margin-left: auto;
    margin-right: 0;
  }

  svg {
    display: block;
  }
`

export default Header
