function FiveStars() {
  return (
    <svg 
      width="69"
      height="12"
      viewBox="0 0 69 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.801 3.59629H7.89462L6.44141 0.413773C6.36773 0.274416 6.2538 0.160477 6.11444 0.0868013C6.02529 0.0402007 5.9278 0.0116905 5.82759 0.00291116C5.72738 -0.00586815 5.62643 0.00525686 5.53053 0.035646C5.43464 0.0660351 5.3457 0.115088 5.26884 0.179983C5.19197 0.244877 5.1287 0.324331 5.08266 0.413773L3.62946 3.59629H0.723046C0.617997 3.59342 0.513576 3.61338 0.416985 3.65477C0.320394 3.69617 0.233932 3.75802 0.163562 3.83607C0.0462503 3.97989 -0.0115818 4.16314 0.00192925 4.34824C0.0154403 4.53335 0.0992702 4.70626 0.236222 4.83152L2.72847 7.28744L1.34792 10.4627C1.26284 10.608 1.23084 10.7783 1.25741 10.9445C1.28397 11.1108 1.36744 11.2627 1.49356 11.3742C1.61968 11.4857 1.78062 11.55 1.94887 11.556C2.11712 11.5621 2.28225 11.5095 2.41603 11.4073L5.76567 9.52537L9.11531 11.4073C9.24967 11.5012 9.41184 11.5469 9.57548 11.537C9.73912 11.5271 9.8946 11.4622 10.0167 11.3528C10.1387 11.2433 10.2202 11.0958 10.2478 10.9342C10.2755 10.7727 10.2476 10.6065 10.1689 10.4627L8.78834 7.28744L11.2806 4.83152C11.3564 4.7613 11.4163 4.67572 11.4564 4.58049C11.4965 4.48527 11.5158 4.38259 11.5131 4.2793C11.5022 4.09673 11.4228 3.925 11.2908 3.7984C11.1588 3.67179 10.9839 3.59962 10.801 3.59629Z" fill="#009877" />
      <path d="M24.9709 3.59629H22.0645L20.6113 0.413773C20.5377 0.274416 20.4237 0.160477 20.2844 0.0868013C20.1952 0.0402007 20.0977 0.0116905 19.9975 0.00291116C19.8973 -0.00586815 19.7963 0.00525686 19.7005 0.035646C19.6046 0.0660351 19.5156 0.115088 19.4388 0.179983C19.3619 0.244877 19.2986 0.324331 19.2526 0.413773L17.7994 3.59629H14.893C14.7879 3.59342 14.6835 3.61338 14.5869 3.65477C14.4903 3.69617 14.4039 3.75802 14.3335 3.83607C14.2162 3.97989 14.1583 4.16314 14.1719 4.34824C14.1854 4.53335 14.2692 4.70626 14.4061 4.83152L16.8984 7.28744L15.5178 10.4627C15.4328 10.608 15.4008 10.7783 15.4273 10.9445C15.4539 11.1108 15.5374 11.2627 15.6635 11.3742C15.7896 11.4857 15.9505 11.55 16.1188 11.556C16.287 11.5621 16.4522 11.5095 16.586 11.4073L19.9356 9.52537L23.2852 11.4073C23.4196 11.5012 23.5818 11.5469 23.7454 11.537C23.909 11.5271 24.0645 11.4622 24.1866 11.3528C24.3086 11.2433 24.3901 11.0958 24.4177 10.9342C24.4454 10.7727 24.4176 10.6065 24.3388 10.4627L22.9583 7.28744L25.4505 4.83152C25.5263 4.7613 25.5862 4.67572 25.6263 4.58049C25.6664 4.48527 25.6858 4.38259 25.683 4.2793C25.6721 4.09673 25.5927 3.925 25.4607 3.7984C25.3287 3.67179 25.1538 3.59962 24.9709 3.59629Z" fill="#009877" />
      <path d="M39.1409 3.59629H36.2345L34.7813 0.413773C34.7076 0.274416 34.5936 0.160477 34.4543 0.0868013C34.3651 0.0402007 34.2676 0.0116905 34.1674 0.00291116C34.0672 -0.00586815 33.9663 0.00525686 33.8704 0.035646C33.7745 0.0660351 33.6855 0.115088 33.6087 0.179983C33.5318 0.244877 33.4685 0.324331 33.4225 0.413773L31.9693 3.59629H29.0629C28.9578 3.59342 28.8534 3.61338 28.7568 3.65477C28.6602 3.69617 28.5738 3.75802 28.5034 3.83607C28.3861 3.97989 28.3283 4.16314 28.3418 4.34824C28.3553 4.53335 28.4391 4.70626 28.5761 4.83152L31.0683 7.28744L29.6878 10.4627C29.6027 10.608 29.5707 10.7783 29.5973 10.9445C29.6238 11.1108 29.7073 11.2627 29.8334 11.3742C29.9595 11.4857 30.1205 11.55 30.2887 11.556C30.457 11.5621 30.6221 11.5095 30.7559 11.4073L34.1055 9.52537L37.4552 11.4073C37.5895 11.5012 37.7517 11.5469 37.9153 11.537C38.079 11.5271 38.2344 11.4622 38.3565 11.3528C38.4786 11.2433 38.56 11.0958 38.5877 10.9342C38.6153 10.7727 38.5875 10.6065 38.5087 10.4627L37.1282 7.28744L39.6204 4.83152C39.6962 4.7613 39.7562 4.67572 39.7963 4.58049C39.8364 4.48527 39.8557 4.38259 39.8529 4.2793C39.842 4.09673 39.7626 3.925 39.6306 3.7984C39.4986 3.67179 39.3237 3.59962 39.1409 3.59629Z" fill="#009877" />
      <path d="M53.3108 3.59629H50.4044L48.9512 0.413773C48.8775 0.274416 48.7636 0.160477 48.6242 0.0868013C48.5351 0.0402007 48.4376 0.0116905 48.3374 0.00291116C48.2371 -0.00586815 48.1362 0.00525686 48.0403 0.035646C47.9444 0.0660351 47.8555 0.115088 47.7786 0.179983C47.7017 0.244877 47.6385 0.324331 47.5924 0.413773L46.1392 3.59629H43.2328C43.1278 3.59342 43.0233 3.61338 42.9268 3.65477C42.8302 3.69617 42.7437 3.75802 42.6733 3.83607C42.556 3.97989 42.4982 4.16314 42.5117 4.34824C42.5252 4.53335 42.609 4.70626 42.746 4.83152L45.2382 7.28744L43.8577 10.4627C43.7726 10.608 43.7406 10.7783 43.7672 10.9445C43.7937 11.1108 43.8772 11.2627 44.0033 11.3742C44.1294 11.4857 44.2904 11.55 44.4586 11.556C44.6269 11.5621 44.792 11.5095 44.9258 11.4073L48.2754 9.52537L51.6251 11.4073C51.7594 11.5012 51.9216 11.5469 52.0852 11.537C52.2489 11.5271 52.4044 11.4622 52.5264 11.3528C52.6485 11.2433 52.73 11.0958 52.7576 10.9342C52.7852 10.7727 52.7574 10.6065 52.6786 10.4627L51.2981 7.28744L53.7904 4.83152C53.8661 4.7613 53.9261 4.67572 53.9662 4.58049C54.0063 4.48527 54.0256 4.38259 54.0229 4.2793C54.0119 4.09673 53.9325 3.925 53.8005 3.7984C53.6685 3.67179 53.4937 3.59962 53.3108 3.59629Z" fill="#009877" />
      <path d="M67.4827 3.59629H64.5763L63.1231 0.413773C63.0494 0.274416 62.9354 0.160477 62.7961 0.0868013C62.7069 0.0402007 62.6094 0.0116905 62.5092 0.00291116C62.409 -0.00586815 62.3081 0.00525686 62.2122 0.035646C62.1163 0.0660351 62.0273 0.115088 61.9505 0.179983C61.8736 0.244877 61.8103 0.324331 61.7643 0.413773L60.3111 3.59629H57.4047C57.2996 3.59342 57.1952 3.61338 57.0986 3.65477C57.002 3.69617 56.9156 3.75802 56.8452 3.83607C56.7279 3.97989 56.6701 4.16314 56.6836 4.34824C56.6971 4.53335 56.7809 4.70626 56.9179 4.83152L59.4101 7.28744L58.0296 10.4627C57.9445 10.608 57.9125 10.7783 57.939 10.9445C57.9656 11.1108 58.0491 11.2627 58.1752 11.3742C58.3013 11.4857 58.4623 11.55 58.6305 11.556C58.7988 11.5621 58.9639 11.5095 59.0977 11.4073L62.4473 9.52537L65.7969 11.4073C65.9313 11.5012 66.0935 11.5469 66.2571 11.537C66.4208 11.5271 66.5762 11.4622 66.6983 11.3528C66.8204 11.2433 66.9018 11.0958 66.9295 10.9342C66.9571 10.7727 66.9293 10.6065 66.8505 10.4627L65.47 7.28744L67.9622 4.83152C68.038 4.7613 68.098 4.67572 68.1381 4.58049C68.1781 4.48527 68.1975 4.38259 68.1947 4.2793C68.1838 4.09673 68.1044 3.925 67.9724 3.7984C67.8404 3.67179 67.6655 3.59962 67.4827 3.59629Z" fill="#009877" />
    </svg>
  )
}

export default FiveStars
