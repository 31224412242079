import styled from 'styled-components'

const FlexWrap = styled.div<{
  $gap?: number,
}>`
  display: flex;
  flex-wrap: wrap;
  /* gap: calc( ${ p => p.$gap } * var( --grid-gutter ) ) calc( ${ p => p.$gap } * var( --stack-basis ) ); */
  margin: 
    calc( ${ p => p.$gap / 2 } * var( --stack-basis ) * -1 ) 
    calc( ${ p => p.$gap / 2 } * var( --grid-gutter ) * -1 );

  > * {
    flex-grow: 1;
    margin: 
      calc( ${ p => p.$gap / 2 } * var( --stack-basis ) ) 
      calc( ${ p => p.$gap / 2 } * var( --grid-gutter ) );
  }
`

FlexWrap.defaultProps = {
  $gap: 1,
}

export const FlexWrapItem = styled.div<{
  $flexBasis?: string,
  $flexGrow?: number,
}>`
  flex-basis: ${ p => p.$flexBasis };
  flex-grow: ${ p => p.$flexGrow };
`

export default FlexWrap
