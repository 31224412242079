import styled from 'styled-components'
import { useCraft } from '../lib/craft'
import PageSegments from './pageSegments/PageSegments'

function Footer( { pageSegments = null } ) {
  const craftData = useCraft()
  const segments = pageSegments || craftData?.pageProps?.footer?.[ 0 ]?.pageSegments

  if ( !segments ) {
    return null
  }

  return (
    <StyledFooter>
      <PageSegments pageSegments={ segments } />
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  margin: 0 auto;
  /* max-width: 2000px; */
`

export default Footer
