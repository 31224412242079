export default function TelephoneIcon() {
  return (
    <svg 
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M6.34452 7.54331C6.47782 7.41006 6.58356 7.25186 6.65571 7.07775C6.72785 6.90363 6.76498 6.717 6.76498 6.52853C6.76498 6.34006 6.72785 6.15343 6.65571 5.97931C6.58356 5.80519 6.47782 5.64699 6.34452 5.51375L4.82218 3.99212C4.55311 3.72313 4.18822 3.57202 3.80776 3.57202C3.42729 3.57202 3.06241 3.72313 2.79334 3.99212L1.95827 4.82719C1.60831 5.17815 1.39021 5.63915 1.3408 6.13231C1.29139 6.62547 1.41371 7.12057 1.68709 7.53398C4.1442 11.2325 7.31495 14.4032 11.0134 16.8603C11.4269 17.1336 11.9221 17.2558 12.4152 17.2063C12.9084 17.1567 13.3694 16.9385 13.7202 16.5884L14.556 15.7534C14.825 15.4843 14.9761 15.1194 14.9761 14.7389C14.9761 14.3585 14.825 13.9936 14.556 13.7245L13.0337 12.2029C12.7646 11.9339 12.3997 11.7828 12.0193 11.7828C11.6388 11.7828 11.2739 11.9339 11.0048 12.2029L10.4976 12.7101C8.79588 11.3104 7.23415 9.74868 5.83444 8.04693L6.34452 7.54331Z"
        stroke="#009877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M9.5791 0.718506C11.7672 0.718506 13.8657 1.58772 15.4129 3.13494C16.9601 4.68216 17.8293 6.78064 17.8293 8.96874"
        stroke="#009877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M9.5791 3.58813C11.0061 3.58813 12.3747 4.15502 13.3838 5.16407C14.3928 6.17313 14.9597 7.5417 14.9597 8.96872"
        stroke="#009877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M9.5791 6.45776C10.245 6.45776 10.8837 6.72231 11.3546 7.1932C11.8255 7.66409 12.09 8.30276 12.09 8.96871"
        stroke="#009877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
